.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: baseline;
}

.webring {
    display: flex;
    height: 20px;
}

.rights {
  display: flex;
}
.copy {
  margin-right: 10px;
}
.design {
  margin-left: 10px;
  margin-right: 10px;
}

.webringCarousel {
  margin-left: 10px;
}

.contactList {
  display: flex;
  padding: 0;
}
.contactItem {
  list-style: none;
  margin-right: 35px;
  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: 790px) {
  .footer {
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .footer {
    flex-direction: column-reverse;
    flex-wrap: unset;
  }
  .contacts {
    display: flex;
    justify-content: center;
  }
  .rights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
