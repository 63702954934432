.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 30px;
  height: 17px;
}
input:checked + .slider {
  background-color: var(--color-accent-red);
  width: 30px;
  height: 17px;
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--color-accent-red);
}
.slider.round {
  border-radius: 17px;
}
.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0px 7.5px #2020203d;
  background: #fefefe url('../../public/images/night.png') no-repeat center;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background: #fefefe url('../../public/images/sunny.png') no-repeat center;
}

.settings {
  display: flex;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .header {
    min-width: 0;
  }
}
