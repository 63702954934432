[data-theme='dark'] {
    --color-bg: var(--color-bg-dark);
    --color-text: var(--color-text-dark);
}

[data-theme='light'] {
    --color-bg: var(--color-bg-light);
    --color-text: var(--color-text-light);
}

body {
    --color-accent-red: #EF3A3A;
    --color-bg-dark: #070707;
    --color-text-dark: #fefefe;
    --color-bg-light: var(--color-text-dark);
    --color-text-light: var(--color-bg-dark);
    font-family: 'PTRootUIWebRegular', serif;
    background-color: var(--color-bg);
    color: var(--color-text);
}

a {
    color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Geometria', serif;
}

p {
    font-size: larger;
}

.root {
    margin: 50px;
    display: flex;
    flex-direction: column;
    cursor: default;
}

@media only screen and (max-device-width: 480px) {
    .root {
        margin: 5px;
    }
}

.publicationContent > p > img {
    max-height: 600px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dateBox {
    display: flex;
    justify-content: space-between;
}

.foreign {
    text-decoration: underline;
    cursor: pointer;
}

.settings {
    display: flex;
    align-items: center;
}

.languageSwitch {
    cursor: pointer;
    border: var(--color-text) solid 1px;
    margin-right: 10px;
    padding: 2px;
}
