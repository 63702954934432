@import url('https://alexandr-sidorenko.me/fonts/fontello.css');

@font-face {
  font-family: 'fontello';
  src: url('https://alexandr-sidorenko.me/fonts/fontello.eot?87642909');
  src: url('https://alexandr-sidorenko.me/fonts/fontello.woff?87642909')
      format('woff'),
    url('https://alexandr-sidorenko.me/fonts/fontello.ttf?87642909')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PTRootUIWebRegular';
  src: url('https://alexandr-sidorenko.me/fonts/PT Root UI_Regular.eot');
  src: url('https://alexandr-sidorenko.me/fonts/PT Root UI_Regular.woff2')
      format('woff2'),
    url('https://alexandr-sidorenko.me/fonts/PT Root UI_Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria'), url('https://alexandr-sidorenko.me/fonts/Geometria.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Muller';
  src: local('Muller'), url('https://alexandr-sidorenko.me/fonts/Muller.otf');
  font-display: swap;
}

[data-theme='dark'] {
    --color-bg: var(--color-bg-dark);
    --color-text: var(--color-text-dark);
}

[data-theme='light'] {
    --color-bg: var(--color-bg-light);
    --color-text: var(--color-text-light);
}

body {
    --color-accent-red: #EF3A3A;
    --color-bg-dark: #070707;
    --color-text-dark: #fefefe;
    --color-bg-light: var(--color-text-dark);
    --color-text-light: var(--color-bg-dark);
    font-family: 'PTRootUIWebRegular', serif;
    background-color: var(--color-bg);
    color: var(--color-text);
}

a {
    color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Geometria', serif;
}

p {
    font-size: larger;
}

.root {
    margin: 50px;
    display: flex;
    flex-direction: column;
    cursor: default;
}

@media only screen and (max-device-width: 480px) {
    .root {
        margin: 5px;
    }
}

.publicationContent > p > img {
    max-height: 600px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dateBox {
    display: flex;
    justify-content: space-between;
}

.foreign {
    text-decoration: underline;
    cursor: pointer;
}

.settings {
    display: flex;
    align-items: center;
}

.languageSwitch {
    cursor: pointer;
    border: var(--color-text) solid 1px;
    margin-right: 10px;
    padding: 2px;
}

.Footer_footer__oaUOW{display:flex;justify-content:space-between;flex-wrap:wrap-reverse;align-items:baseline}.Footer_webring__ny4Lt{display:flex;height:20px}.Footer_rights__SZ7_D{display:flex}.Footer_copy__4XlNN{margin-right:10px}.Footer_design__o6cdt{margin-left:10px;margin-right:10px}.Footer_webringCarousel___ydyr{margin-left:10px}.Footer_contactList___3wFt{display:flex;padding:0}.Footer_contactItem__7PyqC{list-style:none;margin-right:35px}.Footer_contactItem__7PyqC:last-child{margin-right:0}@media(max-width: 790px){.Footer_footer__oaUOW{justify-content:center;align-items:center}}@media only screen and (min-width: 320px)and (max-width: 480px){.Footer_footer__oaUOW{flex-direction:column-reverse;flex-wrap:unset}.Footer_contacts__dmatX{display:flex;justify-content:center}.Footer_rights__SZ7_D{display:flex;flex-wrap:wrap;justify-content:center}}
.Menu_menu__LwNw1{font-family:"Muller",serif;display:flex;padding:0 10px 10px 0}.Menu_menuItem__QNfeo{list-style:none;margin-right:50px;text-transform:lowercase}.Menu_menuItem__QNfeo:last-child{margin-right:0}.Menu_menuItem__QNfeo.Menu_active__qTFtR{list-style:"▶";color:var(--color-accent-red)}.Menu_menuItem__QNfeo>a{color:var(--color-text);text-decoration:none;margin-left:5px;font-size:x-large}@media(max-width: 320px){.Menu_menuItem__QNfeo{margin-left:0;margin-right:10px}.Menu_menuItem__QNfeo.Menu_active__qTFtR{list-style:none;text-decoration:underline;-webkit-text-decoration-color:var(--color-accent-red);text-decoration-color:var(--color-accent-red)}.Menu_menuItem__QNfeo:last-child{margin-right:unset}.Menu_menuItem__QNfeo>a{font-size:large}}@media(min-width: 321px)and (max-width: 480px){.Menu_menuItem__QNfeo{margin-left:0;margin-right:10px}.Menu_menuItem__QNfeo.Menu_active__qTFtR{list-style:none;text-decoration:underline;-webkit-text-decoration-color:var(--color-accent-red);text-decoration-color:var(--color-accent-red)}.Menu_menuItem__QNfeo:last-child{margin-right:unset}.Menu_menuItem__QNfeo>a{font-size:x-large}}
.Header_header__R_kkv{display:flex;justify-content:space-between;align-items:baseline}.Header_switch__SGbDU{position:relative;display:inline-block;width:30px;height:17px}.Header_switch__SGbDU input{opacity:0;width:0;height:0}.Header_slider__2e7qH{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;transition:.4s;width:30px;height:17px}input:checked+.Header_slider__2e7qH{background-color:var(--color-accent-red);width:30px;height:17px}input:focus+.Header_slider__2e7qH{box-shadow:0 0 1px var(--color-accent-red)}.Header_slider__2e7qH.Header_round__Flypr{border-radius:17px}.Header_slider__2e7qH.Header_round__Flypr:before{border-radius:50%}.Header_slider__2e7qH:before{position:absolute;content:"";height:20px;width:20px;left:0;top:0;bottom:0;margin:auto 0;transition:.4s;box-shadow:0 0px 7.5px rgba(32,32,32,.2392156863);background:#fefefe url(/_next/static/media/night.945f78cc.png) no-repeat center}input:checked+.Header_slider__2e7qH:before{transform:translateX(12px);background:#fefefe url(/_next/static/media/sunny.2dcf371a.png) no-repeat center}.Header_settings__fBnfp{display:flex}@media only screen and (min-device-width: 320px)and (max-device-width: 480px){.Header_header__R_kkv{min-width:0}}
