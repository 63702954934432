.menu {
  font-family: 'Muller', serif;
  display: flex;
  padding: 0 10px 10px 0;
}
.menuItem {
  list-style: none;
  margin-right: 50px;
  text-transform: lowercase;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    list-style: '▶';
    color: var(--color-accent-red);
  }
  & > a {
    color: var(--color-text);
    text-decoration: none;
    margin-left: 5px;
    font-size: x-large;
  }
}

@media (max-width: 320px) {
  .menuItem {
    margin-left: 0;
    margin-right: 10px;
    &.active {
      list-style: none;
      text-decoration: underline;
      text-decoration-color: var(--color-accent-red);
    }
    &:last-child {
      margin-right: unset;
    }
    & > a {
      font-size: large;
    }
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .menuItem {
    margin-left: 0;
    margin-right: 10px;
    &.active {
      list-style: none;
      text-decoration: underline;
      text-decoration-color: var(--color-accent-red);
    }
    &:last-child {
      margin-right: unset;
    }
    & > a {
      font-size: x-large;
    }
  }
}
